import React from 'react';
import { Container, Card, CardTitle, Row, Col } from 'reactstrap';

import buyHome from '../images/buy-home.png';
import renewMortgage from '../images/renew-mortgage.png';
import lookingRefinance from '../images/looking-refinance.png';

const MortgageOptions = () => {
  return (
    <div className="ImgCards">
      <Container>
        <Row>
          <Col className="cardWrapper">
            <Row>
              <Col md={{ size: '4' }}>
                <Card>
                  <img src={buyHome} alt="Buy Home" />
                  <CardTitle>Buying a home?</CardTitle>
                  <p>
                    When buying a home, it is also a good idea to get
                    pre-approved so that you know you can qualify as well as how
                    much you can qualify for. Getting pre-approved and shopping
                    multiple lenders is easy, but it does take a few minutes. We
                    have made it easy to shop and compare lenders with our
                    lender matching service.
                  </p>
                </Card>
              </Col>
              <Col md={{ size: '4' }}>
                <Card>
                  <img src={renewMortgage} alt="Renew Home" />
                  <CardTitle>Renew your mortgage</CardTitle>
                  <p>
                    There are many different loan programs available, including
                    FHA, HARP, VA, HUD, to name a few. Your situation may have
                    changed and it might be time to see if you qualify for one
                    of these government backed programs that are designed to
                    help you save on your monthly payments.
                  </p>
                </Card>
              </Col>
              <Col md={{ size: '4' }}>
                <Card>
                  <img src={lookingRefinance} alt="Looking to Refinance" />
                  <CardTitle>Looking for refinance</CardTitle>
                  <p>
                    Rates are hitting all time lows, it's a great time to look
                    into refinancing your current loan to see if you're eligible
                    for a lower rate. Millions of americans have taken advantage
                    of the falling rates to save on their monthly payments. Some
                    save hundreds each month, thousands every year!
                  </p>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MortgageOptions;
