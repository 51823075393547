import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import { getRedirectURL } from '../utils/utilFunctions';
import {
  Col,
  Button,
  Form,
  FormGroup,
  Card,
  Input,
  CustomInput,
} from 'reactstrap';
const parsed = queryString.parse(
  typeof window !== 'undefined' ? window.location.search : ''
);
const lpValues = ['Refinance', 'Purchase'];
const crValues = ['excellent', 'good', 'average', 'fair', 'poor'];

const UserForm = () => {
  const { register, errors, handleSubmit, setValue } = useForm();
  const onSubmit = (data, e) => {
    const { loan_purpose, credit_rating, zipcode } = data;
    const thankyouPage = getRedirectURL({
      loan_purpose,
      credit_rating,
      zipcode,
    });
    navigate(thankyouPage);
  };

  useEffect(() => {
    // set prepop, storage, and auto redirect here
    (() => {
      const { credit_rating, loan_purpose, zipcode } = parsed;
      let hasCredit = false;
      let hasLP = false;
      let hasZip = false;

      if (credit_rating && crValues.includes(credit_rating)) {
        setValue('credit_rating', credit_rating, { shouldValidate: true });
        hasCredit = true;
      }

      if (loan_purpose && lpValues.includes(loan_purpose)) {
        setValue('loan_purpose', loan_purpose, { shouldValidate: true });
        hasLP = true;
      }

      if (zipcode && /(^\d{5}$)/.test(zipcode)) {
        setValue('zipcode', zipcode, { shouldValidate: true });
        hasZip = true;
      }

      if (hasCredit && hasLP && hasZip) {
        const thankyouPage = getRedirectURL({
          credit_rating,
          loan_purpose,
          zipcode,
        });

        console.log('Redirecting...');
        navigate(thankyouPage);
      }

      return;
    })();
  }, [setValue]);

  const cleanZip = (e) => {
    const { name, value } = e.target;
    const cleaned = value.replace(/[^0-9]/g, '').substring(0, 5);
    setValue(name, cleaned, { shouldValidate: true });
  };

  return (
    <Col>
      <Card
        style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
      >
        <Form inline onSubmit={handleSubmit(onSubmit)}>
          <div className="FormBar">
            <FormGroup>
              <CustomInput
                type="select"
                className="customSelect"
                id="LoanPurpose"
                name="loan_purpose"
                innerRef={register({ required: true })}
              >
                <option value="">Loan Type</option>
                <option value="Refinance">Refinance</option>
                <option value="Purchase">Purchase</option>
              </CustomInput>
              <div className="error-message">
                {errors.loan_purpose && <span>Please select a Loan Type</span>}
              </div>
            </FormGroup>

            <FormGroup>
              <CustomInput
                type="select"
                className="customSelect"
                id="CreditRating"
                name="credit_rating"
                placeholder="Credit Rating"
                innerRef={register({ required: true })}
              >
                <option value="">Credit Rating</option>
                <option value="excellent">Excellent</option>
                <option value="good">Good</option>
                <option value="average">Average</option>
                <option value="fair">Fair</option>
                <option value="poor">Poor</option>
              </CustomInput>
              <div className="error-message">
                {errors.credit_rating && (
                  <span>Please select a Credit Rating</span>
                )}
              </div>
            </FormGroup>

            <FormGroup row={false}>
              <Input
                name="zipcode"
                placeholder="Zip Code"
                className="zipcode"
                innerRef={register({
                  required: true,
                  min: 5,
                  pattern: /(^\d{5}$)/,
                })}
                onChange={cleanZip}
              />
              <div className="error-message">
                {errors.zipcode && <span>Please enter a valid zip code</span>}
              </div>
            </FormGroup>

            <Button type="submit" className="submit">
              GO
            </Button>
          </div>
        </Form>
      </Card>
    </Col>
  );
};

export default UserForm;
