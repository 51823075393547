import React, { useState } from 'react';
import Modal from 'react-modal';
import { Col } from 'reactstrap';
import legal from '../utils/legal';
import logoFooter from '../images/logo-footer.png';

const SetDangerously = ({ message }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    />
  );
};

const Footer = () => {
  Modal.setAppElement('#___gatsby');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const openModal = (e) => {
    const dataId = e.target.dataset.id;

    if (dataId === 'tos') {
      setModalMessage(legal.terms);
    } else {
      setModalMessage(legal.privacy);
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Col>
        <div className="Footer">
          <p>IMPORTANT DISCLOSURES</p>
          <p>
            This website provides information about mortgages and does not
            directly offer mortgages, mortgage applications or approve loans –
            we work hard to match you with lenders who do. The owner of this
            website is not responsible for any photographic or typographical
            errors. Subject matter within this website are copyrighted property
            of the owner of this site. This site may provide affiliate and
            partner links. Any service marks, logos, and trademarks
            (collectively the "Trademarks") displayed are registered and/or
            unregistered Trademarks of their respective owners. This site is not
            affiliated with your current mortgage lender/servicer, HUD, VA or
            FHA. This website is not affiliated with any government agency,
            lender, news source or government organization.
          </p>
          <img src={logoFooter} alt="Logo" />
          <hr />
          <div className="Modals">
            <div className="spanWrapper">
              <span
                data-id="tos"
                onClick={openModal}
                onChange={openModal}
                onKeyUp={() => {}}
                role="button"
                tabIndex={0}
              >
                Terms of Use
              </span>
              <span className="separator">|</span>
              <span
                data-id="pp"
                onClick={openModal}
                onChange={openModal}
                onKeyUp={() => {}}
                role="button"
                tabIndex={0}
              >
                Privacy Policy
              </span>
            </div>
          </div>
        </div>
      </Col>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        shouldCloseOnOverlayClick={true}
        className="modalContainer"
      >
        <h3 id="modalCloseX" onClick={closeModal} aria-hidden="true">
          x
        </h3>
        <div className="modalMargin">
          <div className="modalHeaderContainer"></div>
          <div className="modalBodyContainer">
            <SetDangerously message={modalMessage} />
          </div>
        </div>
        <span
          onClick={closeModal}
          onKeyDown={closeModal}
          className="modal-close"
          role="button"
          tabIndex={0}
        >
          Close
        </span>
      </Modal>
    </>
  );
};

export default Footer;
