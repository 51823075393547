import React, { useEffect } from 'react';
import Layout from '../components/layout';
import { Container, Row, Col } from 'reactstrap';
import UserForm from '../components/UserForm';
import MortgageOptions from '../components/MortgageOptions';
import cakePixel from '../utils/cake-pixel';
import Header from '../components/Header';
import Footer from '../components/Footer';

// markup
const IndexPage = () => {
  useEffect(() => {
    (async () => {
      try {
        await cakePixel();
        sessionStorage.setItem(
          'scData',
          typeof window !== 'undefined' && JSON.stringify(window.scData)
        );
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className="wrapper">
      <Header />
      <Layout isfluid={true}>
        <Row>
          <Col className="headings">
            <h1>Compare Rates Made Simple</h1>
            <h3 className="subheading">Apply Now in minutes</h3>
            <p>
              just tell us a few details about yourself and we'll connect you
              with lenders that fit your needs for a personalized quote.
            </p>
          </Col>
        </Row>
        <Row>
          <UserForm />
        </Row>
        <Container>
          <Row>
            <MortgageOptions />
          </Row>
          <Row>
            <Footer />
          </Row>
        </Container>
      </Layout>
    </div>
  );
};

export default IndexPage;
